import React from "react";

type TextInputProps = {
  type: string;
  id?: string;
  name?: string;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
  maxLength?: number;
  disabled?: boolean;
};

// Update the component to use React.forwardRef
const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      type,
      id,
      name,
      placeholder,
      value,
      defaultValue,
      className,
      onChange,
      style,
      maxLength,
      disabled,
    },
    ref
  ) => {
    const predefinedClassName =
      "rounded-xl outline-none text-secondary bg-primary-background text-sm border border-secondary/20 focus:border-secondary/30";
    const combinedClassName = `${predefinedClassName} ${className || ""}`.trim();

    return (
      <input
        disabled={disabled}
        maxLength={maxLength}
        ref={ref}
        type={type}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={combinedClassName}
        defaultValue={defaultValue}
        style={style}
      />
    );
  }
);

export { TextInput };
